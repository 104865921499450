import {
  SET_TRACKER_SUCCESS,
  SET_TRACKER_ERROR,
  LOAD_BUSES_SUCCESS,
  LOAD_BUSES_ERROR,
  SEARCH_BUSES,
  DELETE_TRACKER_SUCCESS
} from "../actions/map";

const initialState = {
  buses: [],
  searchTerm: "",
  trackerId: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_TRACKER_SUCCESS:
      // console.log("SET_TRACKER_SUCCESS");
      return {
        ...state,
        trackerId: action.payload
      };

    case SET_TRACKER_ERROR:
      // console.log("SET_TRACKER_ERROR");
      return {
        ...state
      };

    case DELETE_TRACKER_SUCCESS: {
      return {
        trackerId: "",
        ...state
      };
    }

    case LOAD_BUSES_SUCCESS:
      // console.log("LOAD_BUSES_SUCCESS");
      /*state.buses.forEach((b, i) => {
        if (
          action.payload[i].longitude !== b.longitude ||
          action.payload[i].latitude !== b.latitude
        ) {
          console.log(
            b.deviceName,
            "old:",
            b.longitude,
            b.latitude,
            "new:",
            action.payload[i].longitude,
            action.payload[i].latitude
          );
        }
      });*/
      return {
        ...state,
        buses: action.payload
      };

    case LOAD_BUSES_ERROR:
      // console.log("LOAD_BUSES_ERROR");
      return state;

    case SEARCH_BUSES:
      return {
        ...state,
        searchTerm: action.payload
      };

    default:
      return state;
  }
}
