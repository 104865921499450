import React from "react";
import { connect } from "react-redux";

// import "./App.css";

import {
  openTracker,
  loadBuses,
  closeTracker,
  SEARCH_BUSES
} from "./actions/map";

import { busesAsGeoJSONSelector } from "./selectors/map";

import { Map } from "./components/Map";

const POLLING_FREQ = 15 * 1000;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: ""
    };
  }

  doBeforeUnload = () => {
    this.props.dispatch(closeTracker());
  };

  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", ev => {
      ev.preventDefault();
      return this.doBeforeUnload();
    });
  };

  componentDidMount = () => {
    this.props.dispatch(openTracker());
    this.setupBeforeUnloadListener();
    this.interval = setInterval(this.refresh, POLLING_FREQ);
  };

  componentWillUnmount = () => {
    clearInterval(this.interval);
  };

  refresh = () => {
    this.props.dispatch(loadBuses());
  };

  handleTextChange = searchTerm => {
    this.props.dispatch({ type: SEARCH_BUSES, payload: searchTerm });
  };

  render = () => {
    return (
      <>
        <Map
          busesGeoJSON={this.props.buses}
          searchTerm={this.props.searchTerm}
        ></Map>
        <div className="filter-ctrl">
          <input
            id="filter-input"
            type="text"
            name="filter"
            placeholder="Find Bus ID"
            onChange={e => {
              this.handleTextChange(e.target.value);
            }}
            value={this.props.searchTerm}
          />
        </div>
      </>
    );
  };
}

const mapStateToProps = state => {
  return {
    buses: busesAsGeoJSONSelector(state),
    searchTerm: state.searchTerm,
    trackerId: state.trackerId
  };
};

export default connect(mapStateToProps)(App);
