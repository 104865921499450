import { createTracker, pollTracker, deleteTracker } from "../api/map";

export const SET_TRACKER_SUCCESS = "SET_TRACKER_SUCCESS";
export const SET_TRACKER_ERROR = "SET_TRACKER_ERROR";
export const LOAD_BUSES_SUCCESS = "LOAD_BUSES_SUCCESS";
export const LOAD_BUSES_ERROR = "LOAD_BUSES_ERROR";
export const SEARCH_BUSES = "SEARCH_BUSES";
export const DELETE_TRACKER_SUCCESS = "DELETE_TRACKER_SUCCESS";
export const DELETE_TRACKER_ERROR = "DELETE_TRACKER_ERROR";

export const openTracker = () => {
  return (dispatch, getState) => {
    createTracker(getState()).then(
      trackerId => {
        dispatch({
          type: SET_TRACKER_SUCCESS,
          payload: trackerId
        });
        setTimeout(() => {
          dispatch(loadBuses());
        }, 0);
      },
      error => {
        dispatch({
          type: SET_TRACKER_ERROR,
          payload: error
        });
      }
    );
  };
};

export const closeTracker = () => {
  return (dispatch, getState) => {
    deleteTracker(getState()).then(
      trackerId => {
        dispatch({
          type: DELETE_TRACKER_SUCCESS,
          payload: trackerId
        });
      },
      error => {
        dispatch({
          type: DELETE_TRACKER_ERROR,
          payload: error
        });
      }
    );
  };
};

export const loadBuses = () => {
  return (dispatch, getState) => {
    pollTracker(getState()).then(
      buses => {
        dispatch({
          type: LOAD_BUSES_SUCCESS,
          payload: buses
        });
      },
      error => {
        if (error === "Id not found") {
          dispatch(openTracker());
        } else {
          dispatch({
            type: LOAD_BUSES_ERROR,
            payload: error
          });
        }
      }
    );
  };
};
