import { createSelector } from "reselect";

const busesSelector = state => state.buses;

const searchTermSelector = state => state.searchTerm;

export const busesLocationSelector = createSelector(
  busesSelector,
  searchTermSelector,
  (buses, searchTerm) =>
    buses
      .filter(bus => {
        return bus.deviceName.includes(searchTerm);
      })
      .map(bus => {
        return {
          id: bus.uniqueID,
          name: bus.deviceName,
          lng: bus.longitude,
          lat: bus.latitude,
          // isEngineOn: bus.isEngineOn,
          // speed: bus.speed,
          azimuth: bus.azimuth,
          sendTimeUtc: bus.sendTimeUtc,
          iconName:
            bus.isEngineOn === false
              ? "bus-stop"
              : bus.speed < 1
              ? "bus-idle"
              : "bus-move",
          circleIconName:
            bus.isEngineOn === false
              ? "circle-arrow-stop"
              : bus.speed < 1
              ? "circle-arrow-idle"
              : "circle-arrow-move"
        };
      })
);

const geoJSONFeatureFromBus = bus => {
  const { id, name, iconName, circleIconName, azimuth, lng, lat } = bus;
  return {
    id,
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [lng, lat]
    },
    properties: {
      name,
      iconName,
      circleIconName,
      azimuth
    }
  };
};

export const busesAsGeoJSONSelector = createSelector(
  busesLocationSelector,
  buses => {
    return {
      type: "FeatureCollection",
      features: buses.map(bus => geoJSONFeatureFromBus(bus))
    };
  }
);
