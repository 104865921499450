import React from "react";
import MapGL, {
  NavigationControl,
  GeolocateControl,
  Source,
  Layer,
  Image
} from "@urbica/react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

import bbox from "@turf/bbox";

const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1Ijoic2NwZGMiLCJhIjoiY2syM2p4OXYzMHhrNzNkcWNwdWRwYjhhcyJ9.TGzlr8LovW-R9bn7sWc-tw";

export class Map extends React.Component {
  reactMap;

  constructor(props) {
    super(props);
    this.reactMap = React.createRef();
    this.state = {
      viewport: {
        latitude: 30.6417455,
        longitude: -87.3375787,
        zoom: 9
      }
    };
  }

  updateViewport = viewport => {
    this.setState({ viewport });
  };

  componentDidUpdate = prevProps => {
    const { busesGeoJSON, searchTerm } = this.props;
    if (
      busesGeoJSON.features.length > 0 &&
      busesGeoJSON !== prevProps.busesGeoJSON &&
      searchTerm !== prevProps.searchTerm
    ) {
      const [minLat, minLng, maxLat, maxLng] = bbox(busesGeoJSON);
      const map = this.reactMap.current.getMap();
      map.fitBounds([[minLat, minLng], [maxLat, maxLng]], {
        padding: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }
      });
    }
  };

  render() {
    const { viewport } = this.state;
    const { busesGeoJSON } = this.props;
    return (
      <MapGL
        {...viewport}
        ref={this.reactMap}
        onViewportChange={this.updateViewport}
        style={{ width: "100%", height: "100%" }}
        mapStyle="mapbox://styles/scpdc/ck23jgy4o0fe01cnmbaoorkoo"
        accessToken={MAPBOX_ACCESS_TOKEN}
      >
        <NavigationControl showCompass showZoom position="top-right" />
        <GeolocateControl position="top-right" />
        <Image id="bus-label-bg" image={"./background.png"} />
        <Image id="bus-move" image={"./bus-move.png"} />
        <Image id="bus-idle" image={"./bus-idle.png"} />
        <Image id="bus-stop" image={"./bus-stop.png"} />
        <Image id="circle-arrow-move" image={"./circle-arrow-move.png"} />
        <Image id="circle-arrow-idle" image={"./circle-arrow-idle.png"} />
        <Image id="circle-arrow-stop" image={"./circle-arrow-stop.png"} />
        <Source
          id="buses"
          type="geojson"
          cluster={true}
          clusterMaxZoom={16}
          clusterRadius={32}
          data={busesGeoJSON}
        />
        <Layer
          id="bus-arrows"
          type="symbol"
          source="buses"
          filter={["!has", "point_count"]}
          layout={{
            "icon-image": ["get", "circleIconName"],
            "icon-rotate": ["get", "azimuth"],
            "icon-rotation-alignment": "viewport",
            "icon-allow-overlap": true,
            "icon-anchor": "center",
            "icon-size": 1.2
          }}
        />
        <Layer
          id="bus-icons"
          type="symbol"
          source="buses"
          filter={["!has", "point_count"]}
          layout={{
            "icon-image": ["get", "iconName"],
            "icon-allow-overlap": true,
            "icon-anchor": "center"
          }}
        />
        <Layer
          id="bus-labels"
          type="symbol"
          source="buses"
          filter={["!has", "point_count"]}
          layout={{
            "icon-image": "bus-label-bg",
            "icon-anchor": "top",
            "icon-text-fit": "both",
            "icon-text-fit-padding": [2, 5, 2, 5],
            "text-field": ["get", "name"],
            "text-font": ["Overpass Regular"],
            "text-anchor": "top",
            "text-size": 12,
          }}
          paint={{
            "text-translate": [0, 25],
            "icon-translate": [0, 25],
            "text-color": "#FFFFFF"
          }}
        />
        <Layer
          id="bus-clusters"
          source="buses"
          type="circle"
          filter={["has", "point_count"]}
          paint={{
            "circle-color": [
              "step",
              ["get", "point_count"],
              "#51bbd6",
              50,
              "#f1f075",
              200,
              "#f28cb1"
            ],
            "circle-radius": [
              "step",
              ["get", "point_count"],
              20,
              50,
              30,
              200,
              40
            ]
          }}
        />
        <Layer
          id="bus-cluster-count"
          source="buses"
          type="symbol"
          filter={["has", "point_count"]}
          layout={{
            "text-field": "{point_count_abbreviated}",
            "text-font": ["Arial Unicode MS Bold"],
            "text-size": 14
          }}
          paint={{
            "text-translate": [0, 0],
            "icon-translate-anchor": "viewport",
            "text-translate-anchor": "viewport"
          }}
        />
      </MapGL>
    );
  }
}

/*
        <Source
          id="esri"
          type="raster"
          minzoom={0}
          maxzoom={19}
          tileSize={256}
          tiles={[
            "https://gismaps.myescambia.com/arcgis/rest/services/bus_stops_and_routes_cached/MapServer/export?transparent=true&format=png32&bbox={bbox-epsg-3857}&f=image"
          ]}
        />
        <Layer id="raster" type="raster" source="esri" />
 */

/*
        <Layer
          id="buses-point"
          type="circle"
          source="buses"
          paint={{
            "circle-radius": 5,
            "circle-color": "blue"
          }}
          filter={["!has", "point_count"]}
        />

 */

//             "icon-size": {
//               stops: [[10, 0.05], [14, 2 * 0.05]]
//             },

//           paint={{
//             "icon-translate": [0, -6]
//           }}
