import base64 from "base-64";

export const createTracker = async () => {
  const username = "gpsMobileEscambiaBus1@mygovernmentonline.org";
  const password = "jJK!j98!#&*(dANj98jjlk";
  let headers = new Headers();
  headers.append(
    "Authorization",
    "Basic " + base64.encode(username + ":" + password)
  );
  let response = await fetch(
    "https://gps.mygovernmentonline.org/Gps.Api2/locations/createTracker?jurisdictions=172&departments=2476",
    {
      headers
    }
  );
  const json = await response.json();
  if (
    json.isError === true ||
    json["isError"] === true ||
    json["data"] === null
  ) {
    throw new Error(json.errorMessage);
  }
  return json["data"]["id"];
};

export const pollTracker = async state => {
  let response = await fetch(
    `https://gps.mygovernmentonline.org/Gps.Api2/locations/pollTracker?id=${state.trackerId}`
  );
  const json = await response.json();
  if (
    json.isError === true ||
    json["isError"] === true ||
    json["data"] === null
  ) {
    throw new Error(json.errorMessage);
  }
  return json["data"];
};

export const deleteTracker = async state => {
  let response = await fetch(
    `https://gps.mygovernmentonline.org/Gps.Api2/locations/deleteTracker?id=${state.trackerId}`
  );
  const json = await response.json();
  if (
    json.isError === true ||
    json["isError"] === true ||
    json["data"] === null
  ) {
    throw new Error(json.errorMessage);
  }
  return json["data"]["id"];
};
